const { Configuration, OpenAIApi } = require("openai");

const generateItinerary = async (formData) => {
  try {

    const configuration = new Configuration({
        apiKey: process.env.CHAT_GPT_API_KEY,
      });
    const openai = new OpenAIApi(configuration);
    //console.log(openai)
    const completion = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: `Design a travel itinerary for a ${formData.numDays} day trip during ${formData.startMonth} and ${formData.endMonth} flying into ${formData.startCity}, ${formData.startCountry}. We would like to stay at a couple of different cities in each of the coutries and be travelling around the south coast but are open to being inland depending on the options available considering personality types outlined below. We would like to stay ${formData.numDaysPerCountry} days in each of the countries and flying out of ${formData.endCity}, ${formData.endCountry}. We are a ${formData.familyType} and are on a ${formData.budgetPerDay} a day budget but would love to cut it down by ${formData.costSavingPercent} if possible. We are a personality type that likes ${formData.interests.join(', ')}.`,
      });
  
      console.log(completion.data.choices[0].text);
      return completion.data.choices[0].text
  } catch (error) {
    console.error(error);
  }
}


export default generateItinerary;