import React from 'react';

const Home = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Welcome to the Custom Travel Itinerary App!</h1>
      <button style={{ fontSize: '1.5rem' }}>
        <a href="https://buy.stripe.com/test_bIY5ks1u17sFcZa6oo">Only $0.99</a>
      </button>
    </div>
  );
}

export default Home;
