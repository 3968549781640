import React from 'react';

function Unauthorized() {
  return (
    <div>
      <h1>Unauthorized</h1>
      <p>You are not authorized to access this route.</p>
    </div>
  );
}

export default Unauthorized;