import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './routes/Home';
import Itinerary from './routes/Itinerary';
import Unauthorized from './routes/Unauthorized';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/itinerary" element={<Itinerary />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
