import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Form from '../components/Form';
import generateItinerary from '../utils/itinerary';

function Itinerary() {
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    // Check if the user was redirected from the allowed external webpage
    if (window.referrer.startsWith('https://checkout.stripe.com')) {
      setIsAllowed(true);
    }
  }, []);

  if (isAllowed) {
    // Render the protected route
    return <Form onSubmit={generateItinerary}/>;
  } else {
    // Redirect the user to a different route or display an error message
    return <Navigate to="/unauthorized" />;
  }
}

export default Itinerary;