import React, { useState } from 'react';


const Form = (props) => {
  const [formData, setFormData] = useState({
    // Initialize state with empty values for form variables
    numDays: '',
    startMonth: '',
    endMonth: '',
    startCity: '',
    startCountry: '',
    numDaysPerCountry: '',
    endCity: '',
    endCountry: '',
    familyType: '',
    budgetPerDay: '',
    costSavingPercent: '',
    interests: []
  });

  const handleChange = (event) => {
    // Update state with new form values as the user types
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // Pass form values to parent component for processing
    props.onSubmit(formData);
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Number of days:
        <input
          name="numDays"
          type="number"
          value={formData.numDays}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Start month:
        <input
          name="startMonth"
          type="text"
          value={formData.startMonth}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        End month:
        <input
          name="endMonth"
          type="text"
          value={formData.endMonth}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        City flying into:
        <input
          name="startCity"
          type="text"
          value={formData.startCity}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Country flying into:
        <input
          name="startCountry"
          type="text"
          value={formData.startCountry}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Days in each country:
        <input
          name="numDaysPerCountry"
          type="number"
          value={formData.numDaysPerCountry}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        City flying out of:
        <input
          name="endCity"
          type="text"
          value={formData.endCity}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Country flying out of:
        <input
          name="endCountry"
          type="text"
          value={formData.endCountry}
          onChange={handleChange}
        />
      </label>
      <br />
      <br />
      <label>
        Family type:
        <input
            name='familyType'
            type='text'
            value={formData.familyType}
            onChange={handleChange}
        />
        </label>
        <label>
        Budget per day:
        <input
          name="budgetPerDay"
          type="number"
          value={formData.budgetPerDay}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Cost saving percentage:
        <input
          name="costSavingPercent"
          type="number"
          value={formData.costSavingPercent}
          onChange={handleChange}
        />
      </label>
      <br />
      <label>
        Interests:
        <input
          name="interests"
          type="text"
          value={formData.interests.join(', ')}
          onChange={handleChange}
        />
      </label>
      <br />
      <button type="submit">Generate itinerary</button>
    </form>
  );
}

export default Form;
